import React, { useEffect, useState } from "react";
import { Button, TextField } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import history from "../history";
import Loading from "../components/Loading/Loading";
import useFetch from "../hooks/useFetch";
import AlertErr from "../components/Alert/AlertErr";
import PodoApi from "../api/PodoApi";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import useToast from "../hooks/useToas";
import DebugLog from "../components/DebugLog";
import { LINKS } from '../LINKS'
import { Buffer } from 'buffer'
import { IUser } from '../types/IUser'

const UsersPage = () => {

  const [cddid, setCddid] = useState('');//1461
  const [email, setEmail] = useState('');//vit.dolinek@qcm.cz

  const [role, setRole] = useState('')
  const [maxRole, setMaxRole] = useState('')
  const [issuer, setIssuer] = useState('')

  const [roles, setRoles] = useState<{ label: string, value: string }[]>([])
  const [issuers, setIssuers] = useState<{ label: string, value: string }[]>([])

  const [showFilterEmail, setShowFilterEmail] = useState(false)
  const [showFilterCddid, setShowFilterCddid] = useState(false)
  const { toastErr } = useToast()

  useEffect(() => {
    PodoApi.getIssuers()
      .then(data => {
        //console.log('data', data)
        setIssuers(data.map((item: any) => ({
          label: item.identifier,
          value: new Buffer(item.identifier).toString('base64')
        })))
      })
      .catch(err => {
        toastErr('Chyba nacteni issuers ' + err.toString())
      })

    PodoApi.getRoles()
      .then(data => {
        //console.log('data roles', data)
        setRoles(Object.values(data).map((item: any) => ({
          label: item.value,
          value: item.value
        })))
      })
      .catch(err => {
        toastErr('Chyba nacteni roles ' + err.toString())
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [searchByEmail, setSearchByEmail] = useState(false)
  const [searchTitle, setSearchTitle] = useState('')

  const fetchCddid = useFetch(
    () => PodoApi.getUserByCddid(cddid, issuer || undefined)
  )
  const fetchEmail = useFetch(
    () => PodoApi.getUsersByEmail(email, role || undefined, maxRole || undefined)
  )

  const clickSearchEmail = () => {
    fetchEmail.fetch()
    setSearchByEmail(true)
    setSearchTitle(`Vyhledáno dle email: ${email}`)
  }
  const clickSearchCddid = () => {
    fetchCddid.fetch()
    setSearchByEmail(false)
    setSearchTitle(`Vyhledáno dle cddid/id: ${cddid}`)
  }

  const toDetail = (id: string) => {
    history.push(`${LINKS.uzivatel}/${id}`)
  }

  const loading = searchByEmail ? fetchEmail.loading : fetchCddid.loading
  const err = searchByEmail ? fetchEmail.err : fetchCddid.err
  const users = searchByEmail ? fetchEmail.data : (fetchCddid.data ? [fetchCddid.data] : null)

  return (
    <div>
      <Breadcrumbs items={[
        { label: "Home", link: "/" },
        { label: "Uživatelé" },
      ]}/>
      <h1>Vyhledání uživatele</h1>

      <div>
        <TextField id="standard-cddid" label="cddid/id" value={cddid}
                   onChange={e => setCddid(e.target.value)}
                   InputProps={{
                     endAdornment:
                       <Button variant="contained" color="primary" onClick={clickSearchCddid}>Vyhledat</Button>
                   }}/>


        {showFilterCddid ?
          <FormControl style={{ width: 150, margin: '0px 10px' }}>
            <InputLabel>Issuer</InputLabel>
            <Select
              value={issuer}
              onChange={(e) => setIssuer(e.target.value as string)}
            >
              <MenuItem value="">Vybrat...</MenuItem>
              {issuers.map(({ value, label }) =>
                <MenuItem key={value} value={value}>{label}</MenuItem>
              )}
            </Select>
          </FormControl>
          :
          <Button style={{ marginTop: 20 }} size="small" onClick={() => setShowFilterCddid(true)}>Filtrovat</Button>
        }
      </div>
      <br/>
      <div>
        <TextField id="standard-email" label="E-mail" value={email}
                   onChange={e => setEmail(e.target.value)}
                   InputProps={{
                     endAdornment:
                       <Button variant="contained" color="primary" onClick={clickSearchEmail}>Vyhledat</Button>
                   }}/>
        {showFilterEmail ?
          <>
            <FormControl style={{ width: 150, margin: '0px 10px' }}>
              <InputLabel>role</InputLabel>
              <Select
                value={role}
                onChange={(e) => setRole(e.target.value as string)}
              >
                <MenuItem value="">Vybrat...</MenuItem>
                {roles.map(({ value, label }) =>
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                )}
              </Select>
            </FormControl>

            <FormControl style={{ width: 150, margin: '0px 10px' }}>
              <InputLabel>maxRole</InputLabel>
              <Select
                value={maxRole}
                onChange={(e) => setMaxRole(e.target.value as string)}
              >
                <MenuItem value="">Vybrat...</MenuItem>
                {roles.map(({ value, label }) =>
                  <MenuItem key={value} value={value}>{label}</MenuItem>
                )}
              </Select>
            </FormControl>
          </>
          :
          <Button style={{ marginTop: 20 }} size="small" onClick={() => setShowFilterEmail(true)}>Filtrovat</Button>
        }
      </div>
      <br/>
      <br/>


      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {(!(loading || err) && users) &&
      <>
        <DebugLog val={users}/>
        <h3>{searchTitle}</h3>

        {users.length === 0 ?
          <>Žádný záznam</>
          :
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>CDD ID </TableCell>
                  <TableCell align="center">E-mail</TableCell>
                  <TableCell align="center">Příjmení</TableCell>
                  <TableCell align="center">Jméno</TableCell>
                  <TableCell align="center">Role</TableCell>
                  <TableCell align="center">Issuer</TableCell>
                  <TableCell align="center">Podo ID</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user: IUser) => (
                  <TableRow key={user.id} style={{ cursor: "pointer" }} onClick={() => toDetail(user.id)}>
                    <TableCell component="th" scope="row">
                      {user.cddId}
                    </TableCell>
                    <TableCell align="center">{user.email}</TableCell>
                    <TableCell align="center">{user.firstName}</TableCell>
                    <TableCell align="center">{user.lastName}</TableCell>
                    <TableCell align="center">{user.roles?.join(', ')}</TableCell>
                    <TableCell align="center">{user.issuer?.identifier}</TableCell>
                    <TableCell align="center">{user.id}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        }
      </>
      }

    </div>


  )
}

export default UsersPage;
