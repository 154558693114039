import { Button, TextField } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import React, { useEffect, useState } from 'react'
import PodoApi from '../api/PodoApi'
import AlertErr from '../components/Alert/AlertErr'
import Breadcrumbs from '../components/Breadcrumbs/Breadcrumbs'
import Loading from '../components/Loading/Loading'
import NotificationsTable from '../components/Notifications/NotificationsTable'
import useFetchNotifications from '../hooks/useFetchNotifications'
import useToast from '../hooks/useToas'
import { LINKS } from '../LINKS'

const NotificationPage = () => {
  const { toastErr } = useToast()

  const [contractId, setContractId] = useState('')
  const [userId, setUserId] = useState('')
  const [slug, setSlug] = useState('')

  const [fetchAfterLoad, setFetchAfterLoad] = useState(false)
  const [showContractId, setShowContractId] = useState(false)

  const {
    loading,
    err,
    notifs,
    fetch,
    page,
    pagesCount,
    setPage
  } = useFetchNotifications({ contractId: contractId || undefined, userId: userId || undefined })

  const clickSearch = () => {
    setContractId('')
    setFetchAfterLoad(false)
    const lastSlug = slug.split('/').pop()
    if (lastSlug) {
      PodoApi.getContractDetail(lastSlug)
        .then(data => {
          setContractId(data.id)
          setFetchAfterLoad(true)
        })
        .catch(e => {
          toastErr('Zakázka nenelezena')
        })
    } else {
      fetch()
    }
  }

  useEffect(() => {
    if (fetchAfterLoad) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAfterLoad])

  // const toDetail = (id: string) => {
  //   history.push(`${LINKS.uzivatel}/${id}`)
  // }

  return (
    <div>
      <Breadcrumbs items={[
        { label: 'Home', link: LINKS.home },
        { label: 'Notifikace' },
      ]}/>
      <h1>Dohledání, zda byl uživatel notifikován na konkrétní zakázku</h1>

      <div style={{ display: 'flex', gap: 30, flexDirection: 'column', maxWidth: 500 }}>

        <TextField id="standard-userid" label="Cddid/id uživatele" value={userId}
                   onChange={e => setUserId(e.target.value)}/>


        <TextField id="standard-slug" label="Slug/URL zakázky" value={slug}
                   onChange={e => setSlug(e.target.value)}/>


        {showContractId ?
          <TextField id="standard-contractid" label="ID zakázky" value={contractId}
                     onChange={e => setContractId(e.target.value)}/>
          :
          <Button style={{ marginTop: -30 }} size="small" onClick={() => setShowContractId(true)}>zobrazit id
            zakázky</Button>
        }


        <Button variant="contained" color="primary" onClick={clickSearch} disabled={!userId || !slug}
        >Vyhledat</Button>
      </div>

      <br/>
      <br/>

      {loading && <Loading/>}
      {err && <AlertErr>{err}</AlertErr>}

      {
        (!(loading || err) && notifs) &&
        <>
          <NotificationsTable notifs={notifs}/>

          <Pagination
            style={{ margin: '10px' }}
            count={pagesCount}
            page={page}
            onChange={(e, val) => setPage(val)}/>
        </>
      }

    </div>
  )
}

export default NotificationPage
