import { useEffect, useState } from 'react'
import PodoApi from '../api/PodoApi'
import useFetch from './useFetch'

const useFetchNotifications = ({ contractId, userId }: {
  contractId?: string,
  userId?: string,
}) => {
  const PER_PAGE = 100

  const { loading, err, data, fetch } = useFetch(
    () => PodoApi.notificationFilter({ contractId, userId, page, itemsPerPage: PER_PAGE })
  )

  const [page, setPage] = useState(1)
  const { data: notifs, totalCount } = data ?? {}
  const pagesCount = Math.ceil(totalCount / PER_PAGE)

  useEffect(() => {
    if (data) { // jen pri zmene - ne pri init
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page])

  return { loading, err, notifs, fetch, pagesCount, page, setPage }
}

export default useFetchNotifications
