export const LINKS = {
  home: '/',
  uzivatel: '/uzivatel',
  uzivatele: '/uzivatele',
  filtrZakazky: '/filtr/zakazky',
  notifikace: '/notifikace',
  profil: '/profil',
  login: '/login',
  logout: '/logout'
}
