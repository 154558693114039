import axios from 'axios'
import TokenStore from '../services/TokenStore'
import { INofication } from '../types/INofication'
import keycloak from '../utils/keycloak'
import logout from '../utils/logout'

const API_URL = process.env.REACT_APP_PODO_BE_URL

class PodoApi {

  api = axios.create({
    baseURL: API_URL
  })

  setToken = (token: string) => {
    // console.log('SET API TOKEN', token.length)
    this.api.defaults.headers.common['Authorization'] = 'Bearer ' + token
  }

  constructor () {
    this.initInterception()
  }

  initInterception () {
    let refreshTokenPromise: Promise<any> | null = null // this holds any in-progress token refresh requests

    this.api.interceptors.response.use(
      response => response,
      error => {
        // console.log("authTokenInterceptor", error);

        const originalRequest = error.config
        if (error.response?.status !== 401 || originalRequest._retry) {
          return Promise.reject(error)
        }
        originalRequest._retry = true // only one run per request

        if (!refreshTokenPromise) {
          refreshTokenPromise = keycloak.updateToken(-1)
            .then(() => {
              console.log('TOKEN successfully get a new token', keycloak.token?.length)
              this.setToken(keycloak.token || '')
              TokenStore.saveTokens(keycloak.token || '', keycloak.refreshToken || '')
              return keycloak.token
            }).catch((err) => {
              console.error('TOKEN Chyba refresh tokenu', err)
              logout()
            })
            .finally(() => {
              refreshTokenPromise = null // clear state
            })
        }

        return refreshTokenPromise
          .then(token => {
            // console.log('2THEN token', token.length)
            // this.api.defaults.headers.common['Authorization'] = "Bearer " + token;
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          }).catch(error => {
            console.error('ERR refreshtoekn ', error)
            return Promise.reject(error)
          })

      })
  }

  getLogedUserDetail = () =>
    this.api.get(`/v2/users/me`)
      .then(res => res.data)

  getUserDetail = (id: string) =>
    this.api.get(`/v2/users/${id}`)
      .then(res => res.data)

  //TODO api v2 nepodporuje role a maxRole
  getUsersByEmail = (email: string, role?: string, maxRole?: string) =>
    this.api.get(`/v2/users/${email}`, { params: { role, maxRole } })
      .then(res => res.data)

  //TODO api v2 nepodporuje issuer
  getUserByCddid = (cddid: string, issuer?: string) =>
    this.api.get(`/v2/users/${cddid}`, { params: { issuer } })
      .then(res => res.data)

  getFilterDetail = (id: string) =>
    this.api.get(`/v2/filters/${id}`)
      .then(res => res.data)

  getFiltersByUser = (userId: string) =>
    this.api.get(`/v2/filters`, { params: { owner: userId } })
      .then(res => res.data)

  getFiltersHistory = (id: string) =>
    this.api.get(`/v2/filter_versions`, { params: { filter: id, 'order[createdAt]': 'desc' } })
      .then(res => res.data)

  getFilterVersionDetail = (id: string) =>
    this.api.get(`/v2/filter_versions/${id}`)
      .then(res => res.data)

  getCotractsFilter = (filterParam: any, page: number = 1) =>
    this.api.post(`/v2/contracts/search`, filterParam, {
      params: {
        page: page,
        itemsPerPage: 100,
        type: 'all', //TODO alle nebo active ?
      }
    })
      .then(res => res.data)

  getContractDetail = (slug: string) =>
    this.api.get(`/v2/contracts/${slug}`)
      .then((res) => res.data)

  getIssuers = () =>
    this.api.get('/v2/issuers')
      .then(res => res.data)

  getRoles = () =>
    this.api.get('/v2/user_roles')
      .then(res => res.data)

  getSubscriptionsByUser = (userId: string) =>
    //filtrovani - status level createdBy createdManually (boolean) validFrom validTo createdAt
    // razeni - validFrom validTo createdAt
    this.api.get(`/v2/subscriptions`, { params: { owner: userId, 'order[createdAt]': 'desc' } })
      .then(res => res.data)

  getSubscriptionDetail = (id: string) =>
    this.api.get(`/v2/subscriptions/${id}`)
      .then(res => res.data)

  addSubscription = (userId: string, level: string, dateStart: string, dateEnd?: string, descr?: string) =>
    this.api.post(`/v2/subscriptions`, {
      'level': level,
      'validFrom': dateStart,
      'validTo': dateEnd,
      'createdManually': true,
      'owner': '/users/' + userId,
      'status': 'active',
      'description': descr,
    })
      .then(res => res.data)

  changeUserSubscription = (userId: string, subsId: string, planName: string, dateStart: string, dateEnd?: string) =>
    this.api.patch(`/v2/users/${userId}`, {
      activeSubscription: '/subscriptions/' + subsId,
      subscriptionLevel: planName,
      subscriptionStart: dateStart,
      subscriptionEnd: dateEnd,
      subscriptionStatus: 'active'
    }, {
      headers: { 'Content-Type': 'application/merge-patch+json' }
    })

  notificationFilter = ({ contractId, userId, page, itemsPerPage }: {
    contractId?: string,
    userId?: string,
    page: number,
    itemsPerPage: number
  }) =>
    this.api.get<INofication[]>(`/v2/notification_filters`, {
      params: {
        contracts: contractId,
        'notification.owner': userId?.includes('-') ? userId : undefined,
        'notification.owner.cddId': userId?.includes('-') ? undefined : userId,
        page: page,
        itemsPerPage: itemsPerPage,
        'order[notification.sentAt]': 'desc'
      }
    })
      .then(res => ({
        data: res.data,
        totalCount: parseInt(res.headers['x-total-count'])
      }))

}

const PA = new PodoApi()
export default PA
